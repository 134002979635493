
import { ref } from 'vue'
import ClassForm from '../Form/Index.vue'
import { Class, createClass } from 'momai'
export default {
  components: { ClassForm },
  setup: () => {
    const newClass = ref<Class>(createClass())
    return {
      newClass
    }
  }
}
